import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);
export default new Vuex.Store({
    state: {
        wxConfigUrl: '',
        location: null,
        userInfo: null
    },
    getters: {
        getUserInfo(state) {
            if (!state.userInfo) {
                let user = localStorage.getItem('user');
                state.userInfo = user ? JSON.parse(user) : null;
            }
            return state.userInfo;
        }
    },
    mutations: {
        updateWxConfigUrl(state, url) {
            state.wxConfigUrl = url;
        },
        updateLocation(state, location) {
            state.location = location;
        }
    },
    actions: {
        setUserInfo({ state }, user) {
            localStorage.setItem('user', JSON.stringify(user));
            state.userInfo = user;
        }
    }
});
