<template>
    <div class='my-body' :style='backgroundMy'>
        <router-view />
        <van-tabbar placeholder route>
            <van-tabbar-item replace to='/patient/home' icon='home-o'>首页</van-tabbar-item>
            <van-tabbar-item replace to='/patient/recharge' icon='balance-pay'>购买</van-tabbar-item>
            <van-tabbar-item replace to='/patient/reservation' icon='todo-list-o'>我的预约</van-tabbar-item>
            <van-tabbar-item replace to='/patient/knowledge' icon='newspaper-o'>知识库</van-tabbar-item>
            <van-tabbar-item replace to='/patient/my' icon='contact'>我的</van-tabbar-item>
        </van-tabbar>
    </div>
</template>

<script>
export default {
    data() {
        return {
            backgroundMy: {
                backgroundImage: 'url(' + require('@/assets/images/myBg.png') + ')',
                backgroundRepeat: 'no-repeat',
                backgroundSize: '100% 100%',
                backgroundAttachment: 'fixed'
            },
            icon: {
                active: require('@/assets/images/indexDefault.png'),
                inactive: require('@/assets/images/indexActive.png')
            }
        };
    },
    created() {
    }
};
</script>

<style scoped>
.my-body {
    height: 100%;
}

.van-tabbar-item {
    color: #6e6e6e;
}

.van-tabbar-item--active {
    color: #4ab8ab;
}
</style>
