import Vue from 'vue';
import Router from 'vue-router';
import DoctorTabbar from '@/components/doctor-tabbar';
import PatientTabbar from '@/components/patient-tabbar';
import { isIos } from './util';
import store from './store';
import { H5_HOST } from './config';

Vue.use(Router);

const originalPush = Router.prototype.push;
Router.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err);
};
const originalReplace = Router.prototype.replace;
Router.prototype.replace = function replace(location) {
    return originalReplace.call(this, location).catch(err => err);
};

const routes = [
    {
        path: '/',
        redirect: '/passport/wxAuthLogin'
    },
    {
        name: 'passport.wxAuthLogin',
        path: '/passport/wxAuthLogin',
        component: () => import('@/pages/passport/wxAuthLogin'),
        meta: { title: ' ' },
    },
    {
        name: 'passport.login',
        path: '/passport/login',
        component: () => import('@/pages/passport/login'),
        meta: { title: '绑定' }
    },
    {
        name: 'passport.identity',
        path: '/passport/identity',
        component: () => import('@/pages/passport/identity'),
        meta: { title: '选择身份' }
    },
    {
        name: 'doctor',
        path: '/doctor',
        component: DoctorTabbar,
        redirect: '/doctor/home',
        children: [
            {
                name: 'doctor.home',
                path: 'home',
                component: () => import('@/pages/doctor/home'),
                meta: { title: '首页' }
            },
            {
                name: 'doctor.patient',
                path: 'patient',
                component: () => import('@/pages/doctor/patient'),
                meta: { title: '我的患者' }
            },
            {
                name: 'doctor.my',
                path: 'my',
                component: () => import('@/pages/doctor/my'),
                meta: { title: '我的' }
            }
        ]
    },
    {
        name: 'doctor.my.certification',
        path: '/doctor/my/certification',
        component: () => import('@/pages/doctor/my/certification'),
        meta: { title: '医生认证' }
    },
    {
        name: 'doctor.my.inviteCode',
        path: '/doctor/my/inviteCode',
        component: () => import('@/pages/doctor/my/inviteCode'),
        meta: { title: '我的邀请码' }
    },
    {
        name: 'doctor.patient.treatmentBreakdown',
        path: '/doctor/patient/treatmentBreakdown',
        component: () => import('@/pages/doctor/patient/treatmentBreakdown'),
        meta: { title: '治疗明细' }
    },
    {
        name: 'patient',
        path: '/patient',
        component: PatientTabbar,
        redirect: '/patient/home',
        children: [
            {
                name: 'patient.home',
                path: 'home',
                component: () => import('@/pages/patient/home'),
                meta: { title: '耳宁听力康复' }
            },
            {
                name: 'patient.recharge',
                path: 'recharge',
                component: () => import('@/pages/patient/recharge'),
                meta: { title: '购买' }
            },
            {
                name: 'patient.reservation',
                path: 'reservation',
                component: () => import('@/pages/patient/reservation'),
                meta: { title: '我的预约' }
            },
            {
                name: 'patient.knowledge',
                path: 'knowledge',
                component: () => import('@/pages/patient/knowledge'),
                meta: { title: '知识库' }
            },
            {
                name: 'patient.my',
                path: 'my',
                component: () => import('@/pages/patient/my'),
                meta: { title: '我的' }
            }
        ]
    },
    {
        name: 'patient.reservation.clinic',
        path: '/patient/reservation/clinic',
        component: () => import('@/pages/patient/reservation/clinic'),
        meta: { title: '服务预约' }
    },
    {
        name: 'patient.reservation.clinic.select',
        path: '/patient/reservation/clinic/select',
        component: () => import('@/pages/patient/reservation/select-clinic-list'),
        meta: { title: '选择服务中心' }
    },
    {
        name: 'patient.reservation.activity',
        path: '/patient/reservation/activity',
        component: () => import('@/pages/patient/reservation/activity'),
        meta: { title: '活动预约' }
    },
    {
        name: 'patient.knowledge.detail',
        path: '/patient/knowledge/detail',
        component: () => import('@/pages/patient/knowledge/detail'),
        meta: { title: '文章详情' }
    },
    {
        name: 'patient.my.editUser',
        path: '/patient/my/editUser',
        component: () => import('@/pages/patient/my/editUser'),
        meta: { title: '“悦听悦心”健康会会员信息' }
    },
    {
        name: 'patient.my.equipments',
        path: '/patient/my/equipments',
        component: () => import('@/pages/patient/my/myEquipments'),
        meta: { title: '我的治疗仪' }
    },
    {
        name: 'patient.my.rechargeRecords',
        path: '/patient/my/rechargeRecords',
        component: () => import('@/pages/patient/my/rechargeRecords'),
        meta: { title: '我的充值记录' }
    },
    {
        name: 'patient.my.testReports',
        path: '/patient/my/testReports',
        component: () => import('@/pages/patient/my/testReports'),
        meta: { title: '我的测试报告' }
    },
    {
        name: 'patient.my.testReportDetail',
        path: '/patient/my/testReportDetail',
        component: () => import('@/pages/patient/my/testReportDetail'),
        meta: { title: '测试报告详情' }
    },
    {
        name: 'patient.my.courseRecords',
        path: '/patient/my/courseRecords',
        component: () => import('@/pages/patient/my/courseRecords'),
        meta: { title: '我的疗程记录' }
    },
    {
        name: 'patient.my.invitationCode',
        path: '/patient/my/invitationCode',
        component: () => import('@/pages/patient/my/invitationCode'),
        meta: { title: '我的邀请码' }
    },
    {
        name: 'patient.my.myFriend',
        path: '/patient/my/myFriend',
        component: () => import('@/pages/patient/my/myFriend'),
        meta: { title: '我的朋友' }
    }
];

const router = new Router({
    mode: 'history',
    routes
});

router.beforeEach((to, from, next) => {
    // 如果是安卓，或者ios第一次进来赋值
    if (isIos()) {
        if (!store.state.wxConfigUrl) store.commit('updateWxConfigUrl', location.href);
    } else {
        store.commit('updateWxConfigUrl', H5_HOST + to.fullPath);
    }
    const title = to.meta && to.meta.title;
    if (title) {
        document.title = title;
    }
    next();
});
router.afterEach(() => {
});
export default router;
