import axios from 'axios';
import { BASE_HOST } from '../config';
import Router from '@/router';

const Method = {
    get: 'get',
    post: 'post',
    put: 'put',
    delete: 'delete'
};
axios.interceptors.response.use((response) => {
    return response;
}, function(error) {
    let { status } = error.response;
    if (status === 401) {
        Router.replace('/passport/wxAuthLogin');
        return Promise.reject();
    }
    return Promise.reject(error);
});

async function request(url, method, params) {
    const userId = localStorage.getItem('userId');
    const token = localStorage.getItem('token');
    const headers = {
        'x-auth-token': token || '',
        'x-user-id': userId || ''
    };
    
    let res;
    try {
        switch (method) {
            case Method.get:
                res = await axios.get(url, { params, headers });
                break;
            case Method.post:
                res = await axios.post(url, params, { headers });
                break;
            case Method.put:
                res = await axios.put(url, params, { headers });
                break;
            case Method.delete:
                res = await axios.delete(url, { params, headers });
                break;
            default:
                res = await axios.get(url, { params, headers });
        }
        return res.data;
    } catch (err) {
        console.log(err);
    }
}

// const BaseHost = 'http://127.0.0.1:8199';
export const BaseHost = BASE_HOST;

const BaseUrl = BaseHost + '/h5';

const Url = {
    passport: {
        login: BaseUrl + '/passport/login/by-verify-code',
        refreshToken: BaseUrl + '/passport/refresh-token',
        loginByWxCode: BaseUrl + '/passport/login/by-wxCode',
        bindPhone: BaseUrl + '/passport/login/bindUserPhone',
    },
    sms: {
        sendVerifyCode: BaseUrl + '/sms/send-verify-code'
    },
    user: {
        getUser: BaseUrl + '/user',
        setIdentity: BaseUrl + '/user/identity',
        updateInfo: BaseUrl + '/user/info',
        getMyInviteUsers: BaseUrl + '/user/invite-users'
    },
    payment: {
        createWxPrepay: BaseUrl + '/payment/wx-prepay',
        getPaymentByPaymentNo: BaseUrl + '/payment/by-payment-no'
    },
    region: {
        getRegionTree: BaseUrl + '/region/tree'
    },
    equipment: {
        getMyEquipments: BaseUrl + '/user/equipment/all',
        sendEquipmentUserVerifyCode: BaseUrl + '/user/equipment/send-verify-code',
        addEquipment: BaseUrl + '/user/equipment',
        delEquipment: BaseUrl + '/user/equipment'
    },
    goods: {
        getGoodsList: BaseUrl + '/goods/all'
    },
    clinicReservation: {
        getMyClinicReservations: BaseUrl + '/clinic-reservation/all',
        getClinicWeeks: BaseUrl + '/clinic-reservation/weeks',
        reserveClinic: BaseUrl + '/clinic-reservation/reserve',
        cancelClinicReservation: BaseUrl + '/clinic-reservation/cancel'
    },
    activityReservation: {
        reserveActivity: BaseUrl + '/activity-reservation/reserve',
        deleteActivityReservation: BaseUrl + '/activity-reservation'
    },
    clinic: {
        getClinicCities: BaseUrl + '/clinic/city/all',
        getClinics: BaseUrl + '/clinic/all'
    },
    activity: {
        getActivitiesForUser: BaseUrl + '/activity/all/for-user'
    },
    order: {
        getLatestOrder: BaseUrl + '/order/latest',
        getOrders: BaseUrl + '/order/all',
        createOrder: BaseUrl + '/order'
    },
    equipmentTreatment: {
        getEquipmentTreatments: BaseUrl + '/equipment-treatment/all'
    },
    article: {
        getArticleCategories: BaseUrl + '/article/category/all',
        getArticleList: BaseUrl + '/article/list',
        getArticle: BaseUrl + '/article'
    },
    doctor: {
        getCertification: BaseUrl + '/doctor/certification',
        certificate: BaseUrl + '/doctor/certificate',
        getPatients: BaseUrl + '/doctor/patients',
        getHasDevicePatients: BaseUrl + '/doctor/has-device-patients'
    },
    upload: {
        uploadFiles: BaseUrl + '/upload/files'
    },
    wechat: {
        getJssdkConfig: BaseUrl + '/wechat/jssdk/config'
    },
    seRecord: {
        getSeRecordList: BaseUrl + '/se-record/all',
        getSeRecord: BaseUrl + '/se-record',
        getDbhlList: BaseUrl + '/se-record/dbhl/all'
    }
};

export { Method, request, Url };
