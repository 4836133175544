<template>
    <div class="doctorBody">
        <router-view />
        <van-tabbar v-model="active" placeholder>
            <van-tabbar-item replace to="/doctor/home" icon="home-o">首页</van-tabbar-item>
            <van-tabbar-item replace to="/doctor/patient" icon="friends-o">我的患者</van-tabbar-item>
            <van-tabbar-item replace to="/doctor/my" icon="contact">我的</van-tabbar-item>
        </van-tabbar>
    </div>
</template>

<script>
export default {
    data() {
        return {
            active: 0,
            index: {
                active: require('@/assets/images/indexDefault.png'),
                inactive: require('@/assets/images/indexActive.png'),
            },
            patient: {
                active: require('@/assets/images/patientDefault.png'),
                inactive: require('@/assets/images/patientActive.png'),
            },
        };
    },
    created() {
        if (location.pathname.includes('home')) {
            this.active = 0;
        } else if (location.pathname.includes('patient')) {
            this.active = 1;
        } else if (location.pathname.includes('my')) {
            this.active = 2;
        }
    },
};
</script>

<style scoped>
.doctorBody {
    height: 100%;
}

.van-tabbar-item {
    color: #6e6e6e;
}

.van-tabbar-item--active {
    color: #4ab8ab;
}
</style>
