import { Toast } from 'vant';
import { getMyEquipments } from '@/services/user';
import { IMG_HOST } from './config';
import { format } from 'date-fns';

function verifyPhone(phone) {
    return /^1[34578]\d{9}$/.test(phone);
}

function toast(message) {
    Toast({ message, position: 'bottom' });
}

function toFirstPage(router, user) {
    if (!user) {
        return router.replace({ path: '/passport/wxAuthLogin' });
    }
    const { identity, doctorAuditStatus, phone } = user;
    if(phone == null || phone == "") {
        return router.replace({ path: '/passport/login' });
    }
    switch (identity) {
        case 0:
            return router.replace({ path: '/passport/identity' });
        case 1:
            return router.replace({ path: '/patient' });
        case 2:
            switch (doctorAuditStatus) {
                case 3:
                    return router.replace({ path: '/doctor' });
                default:
                    return router.replace({ path: '/passport/identity' });
            }
    }
}

async function refreshCurSnUser() {
    const curSn = localStorage.getItem('curSn');
    const { code, message, data } = await getMyEquipments();
    if (!code) {
        return toast(message);
    }
    const equipments = data;
    if (equipments.length) {
        const curSnUser = equipments.filter(e => e.sn === curSn)[0];
        localStorage.setItem('curSnUser', JSON.stringify(curSnUser));
    }
}

function calculateDistance(lng1, lat1, lng2, lat2) {
    //纬度差
    let Lat1Radom = (lat1 * Math.PI) / 180.0;
    let Lat2Radom = (lat2 * Math.PI) / 180.0;
    let lat = Lat1Radom - Lat2Radom;
    //经度差
    let Long1Radom = (lng1 * Math.PI) / 180.0;
    let Long2Radom = (lng2 * Math.PI) / 180.0;
    let long = Long1Radom - Long2Radom;
    //计算两点之间的实际距离（经过我的查询这个公式叫做:Haversine公式）
    let s =
        2 *
        Math.asin(
            Math.sqrt(
                Math.pow(Math.sin(lat / 2), 2) +
                Math.cos(Lat1Radom) * Math.cos(Lat2Radom) * Math.pow(Math.sin(long / 2), 2)
            )
        );
    s = s * 6378.137;
    s = Math.round(s * 10000) / 10000;
    //保留最后两位小数
    s = s.toString();
    s = s.substring(0, s.indexOf('.') + 2);
    return s;
}

function getImageUrl(url) {
    return `${IMG_HOST}/${url}`;
}

function isWx() {
    return navigator.userAgent.toLowerCase().indexOf('micromessenger') !== -1;
}

function isIos() {
    return navigator.userAgent.toLowerCase().indexOf('iphone') !== -1;
}

function formatDate(date, formatStr) {
    if (date) {
        if (typeof (date) === 'string') {
            date = date.replace(/-/g, '/');
            return format(new Date(date), formatStr);
        } else {
            return format(date, formatStr);
        }
    }
    return '';
}

function safeNewDate(date) {
    return new Date(date.replace(/-/g, '/'));
}

export { verifyPhone, toast, toFirstPage, refreshCurSnUser, calculateDistance, getImageUrl, isWx, isIos, formatDate, safeNewDate };
