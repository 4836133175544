import Vue from 'vue';
import App from './App.vue';
import '@/vant';
import router from '@/router';
import store from '@/store';
import '../src/assets/css/resetui.css';
import { Lazyload } from 'vant';
import VConsole from 'vconsole';

Vue.config.productionTip = false;
Vue.use(Lazyload, { lazyComponent: true });

if (process.env.VUE_APP_ENV === 'dev') {
    new VConsole();
}
new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app');
