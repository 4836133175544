<template>
    <div id="app">
        <router-view />
    </div>
</template>
<script>
export default {
    name: 'App',
};
</script>

<style>
body {
    font-size: 10px;
    background-color: #f8f8f8;
    -webkit-font-smoothing: antialiased;
}
html,
body,
#app {
    height: 100%;
    background: #fff;
}
#app{
    /* padding-bottom: 50px; */
    height: 100%;
}
.mt-10 {
    margin-top: 10px;
}

/*修复文本编辑器文案布局中*/
.ql-align-center {
    text-align: center !important;
}
</style>
