import Vue from 'vue';
import {
    Tabbar,
    TabbarItem,
    Button,
    Field,
    Toast,
    Tabs,
    Tab,
    Image,
    Row,
    Col,
    Radio,
    RadioGroup,
    Icon,
    Form,
    CellGroup,
    Cell,
    Swipe,
    SwipeItem,
    Card,
    Grid,
    GridItem,
    Dialog,
    Area,
    NavBar,
    Popup,
    Picker,
    DatetimePicker,
    Tag,
    Calendar,
    List,
    Uploader,
    Checkbox,
    CheckboxGroup,
    Empty,
} from 'vant';

Vue.use(Tabbar)
    .use(TabbarItem)
    .use(Button)
    .use(Field)
    .use(Toast)
    .use(Tabs)
    .use(Tab)
    .use(Image)
    .use(Row)
    .use(Col)
    .use(Radio)
    .use(RadioGroup)
    .use(Icon)
    .use(Form)
    .use(CellGroup)
    .use(Cell)
    .use(Swipe)
    .use(SwipeItem)
    .use(Card)
    .use(Grid)
    .use(GridItem)
    .use(Dialog)
    .use(Area)
    .use(NavBar)
    .use(Popup)
    .use(Picker)
    .use(DatetimePicker)
    .use(Tag)
    .use(Calendar)
    .use(List)
    .use(Uploader)
    .use(Checkbox)
    .use(CheckboxGroup)
    .use(Empty);
