import { Method, request, Url } from '@/services/base';

async function getUser() {
    return await request(Url.user.getUser, Method.get);
}

async function setIdentity(identity) {
    return await request(Url.user.setIdentity, Method.put, { identity });
}

async function updateUserInfo({ name, sex, birthday, idCard, province, city, district, address }) {
    return await request(Url.user.updateInfo, Method.put, {
        name,
        sex,
        birthday,
        idCard,
        province,
        city,
        district,
        address,
    });
}

async function getMyEquipments() {
    return await request(Url.equipment.getMyEquipments, Method.get);
}

async function sendBindEquipmentVerifyCode({ sn }) {
    return await request(Url.equipment.sendEquipmentUserVerifyCode, Method.post, { sn });
}

async function addEquipment({ sn, verifyCode }) {
    return await request(Url.equipment.addEquipment, Method.post, { sn, verifyCode });
}

async function delEquipment(sn) {
    return await request(Url.equipment.delEquipment, Method.post, { sn });
}

async function getMyInviteUsers() {
    return await request(Url.user.getMyInviteUsers, Method.get);
}

export {
    getUser,
    setIdentity,
    updateUserInfo,
    getMyEquipments,
    sendBindEquipmentVerifyCode,
    addEquipment,
    delEquipment,
    getMyInviteUsers,
};
